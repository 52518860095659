import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import { GET_CATEGORY_BANNER_INFO } from '@api';
import { CustomLink } from '@components/ui';
import { PromotionBannerItem } from './PromotionBannerItem';

interface props {
  code: string;
  height?: number;
}

export const PromotionBanner: React.FC<props> = ({ code }) => {
  const { i18n } = useTranslation();

  // 카테고리 상품 카드 목록을 가져오는 API
  const { data, loading, error } = useQuery(GET_CATEGORY_BANNER_INFO, {
    variables: {
      godoCateCd: code,
    },
  });

  // 카테고리 배너 정보 중 해당 언어의 정보 추출
  const categoryBannerInfo = !loading && !error && data?.getCategory?.trans?.find((x) => x.lang === i18n.language);

  // 배너 정보가 없거나 API 가 호출되기 까지 스켈레톤 UI 표시
  if (!categoryBannerInfo || !categoryBannerInfo.banner?.image) return <></>;

  const { anchor, anchorTarget, image, color, map } = categoryBannerInfo.banner;
  if (anchor) {
    return (
      <CustomLink href={anchor} newWindow={anchorTarget}>
        <PromotionBannerItem color={color} bannerImageUrl={image} bannerMap={map} />
      </CustomLink>
    );
  }

  return <PromotionBannerItem color={color} bannerImageUrl={image} bannerMap={map} />;
};
