import { LANGUAGE_CODE } from 'src/constants';
import { ThemeMode } from '@components/settings/type';

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS = '/';

export const theme = {
  themeMode: 'toon',
  get mode() {
    return this.themeMode;
  },
  set mode(themeMode: ThemeMode) {
    this.themeMode = themeMode || 'toon';
  },
};

export const PATH_PAGE = {
  root: ROOTS,
  brand: '/brand',
  category: '/category',
  order: '/order',
  mypage: '/mypage',
  inquiry: '/inquiry',
  photoReview: '/photoreviews',
  faq: '/faq',
  faqCreator: '/faq-creator',
  product: '/product',
  event: '/event',
  best: '/best',
  search: '/search',
  users: '/users',
};

export const PATH_MYPAGE = {
  root: PATH_PAGE.mypage,
  orderList: path(PATH_PAGE.mypage, '/order-list'),
};

export const PATH_AUTH = {
  login: '/users/login',
  join: '/users/join',
  joinComplete: '/users/join/complete',
  findId: '/users/find/id',
  findPassword: '/users/find/password',
};

export const PATH_CATEGORY = {
  view: (cateCd: string) => {
    return `${PATH_PAGE.category}/${cateCd}`;
  },
};

export const PATH_BRAND = {
  view: (brandCd: string) => {
    return `${PATH_PAGE.brand}/${brandCd}`;
  },
};

export const PATH_PRODUCT = {
  view(godoGoodsNo: string | number, mainThemeSno?: number, mainThemeName?: string) {
    const viewUrl = path(PATH_PAGE.product, `/${godoGoodsNo}`);

    if (mainThemeSno && mainThemeName) return `${viewUrl}?mtn=${mainThemeSno}%5E%7C%5E${mainThemeName}%5E%7C%5En`;

    return viewUrl;
  },
};

export const PATH_FAQ = {
  root: PATH_PAGE.faq,
};

export const PATH_INQUIRY = {
  root: PATH_PAGE.inquiry,
  list: path(PATH_PAGE.inquiry, '/list'),
  write: path(PATH_PAGE.inquiry, '/write'),
  goodsWrite: (godoGoodsNo: number) => {
    return path(PATH_PAGE.inquiry, `/write?godoGoodsNo=${godoGoodsNo}`);
  },
  business: path(PATH_PAGE.inquiry, '/business'),
  invoice: path(PATH_PAGE.inquiry, '/invoice/receipt'),
  view: (id: number) => {
    return path(PATH_PAGE.inquiry, `/${id}`);
  },
};

export const PATH_PHOTO_REVIEW = {
  root: PATH_PAGE.photoReview,
  image: (id: number) => {
    return path(PATH_PAGE.photoReview, `/${id}`);
  },
  rootForCategory: (categoryCode: string) => {
    return path(PATH_PAGE.photoReview, `?categoryCode=${categoryCode}`);
  },
};

export const PATH_ORDER = {
  root: PATH_PAGE.order,
  checkout: (cartIdx?: string, godoGoodsNo?: number) => {
    return path(PATH_PAGE.order, '/checkout') + (cartIdx ? `?cartIdx=${cartIdx}` : '') + (godoGoodsNo ? `${cartIdx ? '&' : '?'}godoGoodsNo=${godoGoodsNo}` : '');
  },
  list: (language: LANGUAGE_CODE) => {
    return `https://www.acon3d.com/${language}/mypage/order_list.php`;
  },
  view: (orderNo: string) => path(PATH_PAGE.order, `/view/${orderNo}`),
};

export const PATH_FUNDING = {
  root: path(PATH_PAGE.event, '/funding'),
};

export const PATH_STORAGE = {
  root: 'https://storage.acon3d.com',
  publicImage: (imageName: string) => {
    return `${PATH_STORAGE.root}/public-images/${imageName}`;
  },
};
