import dateFormat from 'dateformat';
import React from 'react';
import { useTranslation } from 'next-i18next';
import { noop } from 'lodash';
import { discountAmount } from '@lib/price';
import { CardGroupContainer } from '@components/common/CardGroup/styled';
import { Card, Title } from '@components/common';
import { FlexWrap } from '@components/wrapper';
import useExchange from '@hooks/useExchange';
import CardGroupSkeleton from '@components/common/CardGroup/CardGroupSkeleton';
import { LanguageCode } from '../../../src/constants';
import { ProductCardsWithBrandQuery } from '../../../src/generated/graphql';
import { ICardClickProps } from '../Card/type';

type Props = {
  title: string;
  source: string;
  products: ProductCardsWithBrandQuery['productCards'];
  getProductViewUrl: (val: string) => string;
  isPromotionTimer?: boolean;
  showSeeMore?: boolean;
  isDisplayRankBadge?: boolean;
  handleCardClick?: (props: ICardClickProps, order: number) => void;
};

export default function CardGroup({
  title,
  source,
  products,
  getProductViewUrl,
  isPromotionTimer = false,
  isDisplayRankBadge = false,
  showSeeMore = true,
  handleCardClick = noop,
  ...rest
}: Props): React.ReactElement {
  const { i18n } = useTranslation();
  const { isReady } = useExchange(i18n.language as LanguageCode);
  if (!isReady) return <CardGroupSkeleton />;

  const convertProductCardType = (productInfo: ProductCardsWithBrandQuery['productCards'][0]) => {
    const goodsNo = productInfo.id.toString();

    return {
      ...productInfo,
      productViewUrl: getProductViewUrl(goodsNo),
      goodsNo,
      brandCd: productInfo.brand.code,
      brandName: productInfo.brand.name,
      title: productInfo.title,
      reviewCount: productInfo.reviewCount,
      viewCount: productInfo.viewCount,
      badgeName: productInfo.badgeNames.length > 0 ? productInfo.badgeNames[0] : '',
      languageCode: i18n.language as LanguageCode,
      price: productInfo.salePrice,
      discountPercentage: discountAmount(productInfo.price, productInfo.salePrice),
      subImageUrl: productInfo.mainImageUrl,
      imageUrl: productInfo.subImageUrl,
      isGoods: true,
      extensions: productInfo.extensions.map((extension) => extension),
      isPromotion: Boolean(productInfo.promotionEndDate),
      promotionEndDate: productInfo.promotionEndDate ? dateFormat(productInfo.promotionEndDate, 'yyyy-mm-dd HH:MM:ss') : productInfo.promotionEndDate,
    };
  };

  return (
    <CardGroupContainer {...rest}>
      <Title viewLinkUrl={source} isView={showSeeMore}>
        {title}
      </Title>
      <FlexWrap className={'gap-x-9'}>
        {products.map((goodsInfo, idx) => {
          const cardType = convertProductCardType(goodsInfo);
          return (
            <Card
              {...cardType}
              key={idx}
              ranking={isDisplayRankBadge && idx < 3 && idx + 1}
              isPromotionTimer={isPromotionTimer}
              onClick={(product) => {
                handleCardClick(product, idx + 1);
              }}
            />
          );
        })}
      </FlexWrap>
    </CardGroupContainer>
  );
}
