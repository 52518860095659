import styled from 'styled-components';

export const PromotionBannerContainer = styled.div<{ color: string; isBorder: boolean; borderColor: string }>`
  display: flex;
  justify-content: center;
  ${(props) => `
        background-color: ${props.color}; 
        ${
  props.isBorder &&
          `
            border-bottom: 1px ${props.borderColor} solid;
        `
}
    `}
`;

export const PromotionBannerCenter = styled.div`
  width: 900px;
  margin: 0px auto;
`;

export const PromotionBannerImage = styled.img`
  display: block;
  width: 100%;
`;

export const PromotionBannerSkeleton = styled.div<{ height: number }>`
  width: 100%;
  height: ${(props) => (props.height ? props.height : 160)}px;
`;
