import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useQuery } from '@apollo/client';
import { Transition } from 'react-transition-group';
import AnimateHeight from 'react-animate-height';
import Image from 'next/legacy/image';
import category from '@public/category.json';
import { GET_CATEGORY_CHILD_LIST } from '@api';
import { convertLangCodeForDB } from '@lib';
import { CustomImage, CustomLink, CustomText } from '@components/ui';
import { MAIN_CATEGORY, MAIN_CATEGORY_LIST, MAIN_CATEGORY_NAME, CATEGORY_CODE as CATEGORY_CODE } from 'src/constants';
import { colors } from '@public/theme';
import { DownArrowIcon } from 'src/assets/icons';
import * as Styled from './styled';
import { MainContainer } from './MainContainer';
import { SubContainer } from './SubContainer';
import { Skeleton } from './skeleton';

type props = {
  isShow: boolean;
  isMobile: boolean;
  onClick: () => void;
  onClose?: () => void;
};

export const Category: React.FC<props> = ({ isShow, isMobile, onClick, onClose }) => {
  // 번역도구
  const { i18n, t } = useTranslation(['common', 'category']);
  // 활성화 된 카테고리 코드 (hover 및 click)
  const [activeId, setActiveId] = useState(null);
  // 현재 활성화된 대분류 (카툰렌더, 실사렌더)
  const [activeMainCategory, setActiveMainCategory] = useState(MAIN_CATEGORY.CARTOON);
  // 실사렌더 여부
  const isRealisticTab = MAIN_CATEGORY.REALISTIC === activeMainCategory;
  // 조회할 카테고리 그룹
  const rootCategory = category.rootCategory;
  // 모바일의 경우 카테고리만 펼침
  const defaultGroups = [CATEGORY_CODE.GENRE];
  // if (!isMobile) defaultGroups.push(CATEGORY_CODE.SUBJECT);
  // 활성화 된 카테고리 그룹
  const [activeGroup, setActiveGroup] = useState(defaultGroups);
  // 실사렌더 표시 여부
  const isShowRealitic = false;

  const { data, loading } = useQuery(GET_CATEGORY_CHILD_LIST, {
    variables: {
      lang: convertLangCodeForDB(i18n.language),
      cateCds: rootCategory,
      type: activeMainCategory,
    },
    skip: !isShow,
  });

  useEffect(() => {
    if (!data || !isMobile) return;
    // 항목 객체 배열
    const items = data && data.getTransGodoCategoryChildItems;
    const firstRootCategory = rootCategory.find((code) => items.find((y) => y.code === code));
    const rootCategoryCode = firstRootCategory ? firstRootCategory : rootCategory[0];

    // 기본 활성화된 ID
    const defaultActiveId = items.find((x) => rootCategoryCode === x.code.substring(0, 3) && x.code.length === 6).code;
    // 초기 id 세팅
    if (defaultActiveId) setActiveId(defaultActiveId);
  }, [data]);

  useEffect(() => {
    if (!loading && isShow) document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = null;
    };
  }, [loading, isShow]);

  // 패칭 완료 여부
  const isFetchComplete = !loading && data && data.getTransGodoCategoryChildItems;

  // mouse action 이벤트 처리기 메소드
  const handleMouseAction = (() => {
    // closer
    let timer;
    return {
      // over 시, timer를 설정한다
      active: (code, isDelay) => {
        return () => {
          // delay가 존재하지 않을경우
          if (!isDelay) {
            // 바로 적용
            setActiveId(code);
            // 종료
            return;
          }

          timer = setTimeout(() => {
            setActiveId(code);
          }, 100);
        };
      },
      // timer가 동작하기 전에 timer를 제거함으로 이벤트가 발생하지 않음.
      inActive: () => {
        return () => {
          if (timer) clearTimeout(timer);
        };
      },
    };
  })();

  const handleCategoryGroupClick = (code: string) => {
    const filterGroup = activeGroup.filter((x) => x !== code);
    const groupLength = activeGroup.length;
    const groupValue = groupLength === filterGroup.length ? [code] : [...filterGroup];

    setActiveGroup(groupValue);
    if (
      (activeId && groupLength > 1 && !activeGroup.find((x) => activeId.indexOf(x) > -1)) ||
      (activeId && groupLength <= 1 && activeId.indexOf('code') === -1)
    ) setActiveId(null);
  };

  const subCategoryItems = (code: string) => {
    // 서브 카테고리들
    const items = (data?.getTransGodoCategoryChildItems || []).filter(
      (x) => x.code.substring(0, 6) === code && x.code.length === 9,
    );
    // 모바일인 경우
    if (isMobile && items.length > 0) {
      // 부모 항목
      let parentItem = data.getTransGodoCategoryChildItems.find((x) => x.code === code);
      // 부모 항목의 링크여부가 true 인 경우
      if (parentItem.isLink) {
        // 항목 clone
        parentItem = { ...parentItem };
        // 부모 항목의 이름을 전체보기로 변경
        parentItem.name = t('common:viewAll');
        // 부모 항목 shift
        items.unshift(parentItem);
      }
    }

    return items;
  };

  const handleCategoryClick = () => {
    if (onClose) onClose();
  };

  return (
    <Transition in={isShow} timeout={300}>
      {(state) => {
        return (
          <>
            <Styled.Modal
              state={state}
              isMobile={isMobile}
              isShowSub={Boolean(activeId)}
              onClick={(e) => e.stopPropagation()}
            >
              <Styled.CategoryContainer>
                {isMobile && (
                  <Styled.MobileClose>
                    <Styled.MobileCloseButton onClick={onClick} />
                  </Styled.MobileClose>
                )}

                <Styled.MainContainer isMobile={isMobile}>
                  {isShowRealitic && (
                    <div className={'flex'}>
                      {MAIN_CATEGORY_LIST.map((code, i) => {
                        const isActiveMainCategory = code === activeMainCategory;
                        return (
                          <Styled.MainCategoryButton
                            key={i}
                            onClick={() => {
                              setActiveMainCategory(code);
                            }}
                            isActive={isActiveMainCategory}
                            className={'mb-30px'}
                          >
                            <Styled.MainCategoryText
                              color={isActiveMainCategory ? colors.black.c1 : colors.gray.c11}
                              weight={500}
                            >
                              {t(MAIN_CATEGORY_NAME[code])}
                            </Styled.MainCategoryText>
                          </Styled.MainCategoryButton>
                        );
                      })}
                    </div>
                  )}
                  {isFetchComplete ? (
                    <>
                      <Styled.CategoryWrapper>
                        <Styled.MainCategoryGroup>
                          {isFetchComplete &&
                            (() => {
                              // 리턴 할 배열
                              const returnArr = [];
                              // 항목들
                              const items = data.getTransGodoCategoryChildItems;
                              const categoryLength = rootCategory.length;

                              // 루트 카테고리 개수만큼 루프
                              for (let i = 0; i < categoryLength; i += 1) {
                                // 루트 카테고리 코드
                                const rootCategoryCode = rootCategory[i];
                                // 대분류 카테고리 객체
                                const groupObj = items.find((x) => x.code === rootCategoryCode);
                                // 지정된 대분류 카테고리가 존재하지 않을경우, 다음 루트 카테고리에 대하여 조회한다.
                                if (!groupObj) continue;
                                // 현재 그룹 활성화 여부
                                const isActiveGroup = activeGroup.includes(groupObj.code) || isRealisticTab;

                                // 2차 카테고리에 해당하는 현재 카테고리인 항목 객체 배열
                                const itemObjArr = items.filter(
                                  (x) =>
                                    x.code.substring(0, 3) === rootCategoryCode && x.code.length === 6 && x.isVisible,
                                );

                                // 리턴 할 배열 조립
                                returnArr.push(
                                  <div
                                    className={categoryLength - 1 !== i ? 'mb-7' : ''}
                                    key={`${activeMainCategory}${i}`}
                                  >
                                    <div
                                      className={'flex items-center justify-between cursor-pointer'}
                                      onClick={() => {
                                        handleCategoryGroupClick(groupObj.code);
                                      }}
                                    >
                                      <CustomText color={colors.black.c1} weight={700}>
                                        {groupObj.name}
                                      </CustomText>
                                      {!isRealisticTab && (
                                        <Styled.ArrowIcon rotate={isActiveGroup ? 180 : 0}>
                                          <DownArrowIcon className={'cursor-pointer'} size={20} />
                                        </Styled.ArrowIcon>
                                      )}
                                    </div>

                                    <AnimateHeight
                                      className={isActiveGroup ? 'mt-3' : ''}
                                      height={isActiveGroup ? 'auto' : 0}
                                      duration={300}
                                    >
                                      {itemObjArr.map((x, j) => (
                                        <MainContainer
                                          key={j}
                                          item={x}
                                          activeId={activeId}
                                          isMobile={isMobile}
                                          onMouseAction={handleMouseAction}
                                          onClick={handleCategoryClick}
                                        />
                                      ))}
                                    </AnimateHeight>
                                  </div>,
                                );
                              }
                              return returnArr;
                            })()}
                          {isRealisticTab && (
                            <CustomLink href={`/category/${CATEGORY_CODE.REALISTIC}`}>
                              <Styled.RealisticButton onClick={onClose}>
                                <CustomText>{t('category:realisticHome')}</CustomText>
                              </Styled.RealisticButton>
                            </CustomLink>
                          )}
                        </Styled.MainCategoryGroup>

                        {isMobile && (
                          <SubContainer
                            data={subCategoryItems(activeId)}
                            activeId={activeId}
                            onClick={handleCategoryClick}
                          />
                        )}
                      </Styled.CategoryWrapper>
                      {!isMobile && (
                        <Styled.BannerWrapper>
                          <Styled.BannerLink
                            isMobile={isMobile}
                            href={`https://abler.carpenstreet.com/${i18n.language}`}
                            target="_blank"
                            rel="noreferrer"
                            data-mixpanel-action="click"
                            data-mixpanel-evt="Click Banner"
                            data-mixpanel-name="Abler Renewal"
                            data-mixpanel-position="category hamburger menu"
                            data-mixpanel-link={`https://abler.carpenstreet.com/${i18n.language}`}
                          >
                            <CustomImage
                              src={`/imgs/banner/abler_renewal/img_ctg_banner_${i18n.language}.png`}
                              alt="abler renewal"
                              width={240}
                              height={180}
                              objectFit={'fill'}
                              placeholder={'empty'}
                            />
                          </Styled.BannerLink>
                        </Styled.BannerWrapper>
                      )}
                    </>
                  ) : (
                    <Skeleton />
                  )}
                </Styled.MainContainer>

                {!isMobile && (
                  <SubContainer data={subCategoryItems(activeId)} activeId={activeId} onClick={handleCategoryClick} />
                )}
              </Styled.CategoryContainer>

              {isMobile && (
                <Styled.BannerWrapper isMobile={isMobile}>
                  <Styled.BannerLink
                    isMobile={isMobile}
                    href={`https://abler.carpenstreet.com/${i18n.language}`}
                    target="_blank"
                    rel="noreferrer"
                    data-mixpanel-action="click"
                    data-mixpanel-evt="Click Banner"
                    data-mixpanel-name="Abler Renewal"
                    data-mixpanel-position="category hamburger menu"
                    data-mixpanel-link={`https://abler.carpenstreet.com/${i18n.language}`}
                  >
                    <div>
                      <Image
                        src={`/imgs/banner/abler_renewal/img_ctg_banner_${i18n.language}.png`}
                        alt="abler renewal"
                        layout="fill"
                      />
                    </div>
                  </Styled.BannerLink>
                </Styled.BannerWrapper>
              )}
            </Styled.Modal>
            <Styled.Bg state={state} onClick={onClick} />
          </>
        );
      }}
    </Transition>
  );
};
