//
//  해당 파일은 전역적으로 사용되는 상수들에 대하여 정의하기 위해 생성한 파일입니다.
//

// 상품에 대한 내부 정의 이름
export const GOODS_INTERNAL_NAME = 'goods';

// 배너에 대한 내부 정의 이름
export const BANNER_INTERNAL_NAME = 'banner';

// 고정배너에 대한 내부 정의 이름
export const FIXED_BANNER_INTERNAL_NAME = 'fixedBanner';

export const BEST_INTERNAL_NAME = 'best';

export enum LANGUAGE_CODE {
  KO = 'ko',
  EN = 'en',
  CN = 'zh',
  JP = 'ja',
}
export type LanguageCode = (typeof LANGUAGE_CODE)[keyof typeof LANGUAGE_CODE];

// 언어 한국어 코드
export const LANGUAGE_KO_CODE = 'ko';
// 언어 영문 코드
export const LANGUAGE_EN_CODE = 'en';
// 언어 중국어 코드
export const LANGUAGE_CN_CODE = 'zh';
// 언어 일본어 코드
export const LANGUAGE_JP_CODE = 'ja';

// 환경별 분기
export const IS_STAGING = process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_STAGING === 'true';
export const IS_PROD = process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_STAGING !== 'true';

// 우주최저가 카테고리 코드
export const CATEGORY_CODE_COSMIC_DEAL = '311';
// 기획전 카테고리 코드
export const CATEGORY_CODE_EXHIBITION = '276002';
// 신문고 카테고리 코드
export const CATEGORY_CODE_PAPRE_STORE = '274';
// 혜성 특가 카테고리 코드
export const CATEGORY_CODE_COMET_DEAL = '243';

// 각 페이지에  대한 내부 정의 코드
export enum PAGE_NAME {
  // 검색 결과 페이지에 대한 내부 정의 코드
  SEARCH = 'search',
  // 카테고리 페이지에 대한 내부 정의 코드
  CATEGORY = 'category',
  // 브랜드 페이지에 대한 내부 정의 코드
  BRAND = 'brand',
  // 신작 페이지에 대한 내부 정의 코드
  NEW = 'new',
  // 혜택관 페이지에 대한 내부 정의 코드 ( 추후 독점관으로 통폐합 예정 )
  COMET_DEAL = 'comet-deal',
  // 우주최저가 페이지에 대한 내부 정의 코드 ( 추후 독점관으로 통폐합 예정 )
  COSMIC_DEAL = 'cosmic-deal',
  // 독점관 페이지에 대한 내부 정의 코드
  ONLY_ACON = 'only-acon',
  SCRAP = 'scrap',
}

// 확장자 코드
export enum EXTENSION_CODE {
  SKP = 'skp',
  CS3O = 'cs3o',
  BLEND = 'blend',
  OBJ = 'obj',
  FBX = 'fbx',
  ETC = 'etc',
}

// SKP 확장자 카테고리 코드
export const CATEGORY_CODE_SKP = '095001';
// cs3o 확장자 카테고리 코드
export const CATEGORY_CODE_CS3O = '095003';
// blend 확장자 카테고리 코드
export const CATEGORY_CODE_BLEND = '095015';
// blend 확장자 카테고리 코드(노출)
export const CATEGORY_CODE_BLEND_MODEL = '282002';
// obj 확장자 카테고리 코드
export const CATEGORY_CODE_OBJ = '095004';
// fbx 확장자 카테고리 코드
export const CATEGORY_CODE_FBX = '095002';

export const EXTENSIONS = [
  {
    name: EXTENSION_CODE.SKP,
    code: CATEGORY_CODE_SKP,
  },
  {
    name: EXTENSION_CODE.CS3O,
    code: CATEGORY_CODE_CS3O,
  },
  {
    name: EXTENSION_CODE.BLEND,
    code: CATEGORY_CODE_BLEND,
  },
  {
    name: EXTENSION_CODE.OBJ,
    code: CATEGORY_CODE_OBJ,
  },
  {
    name: EXTENSION_CODE.FBX,
    code: CATEGORY_CODE_FBX,
  },
  {
    name: EXTENSION_CODE.ETC,
    code: null,
  },
];

export enum APPLICATION_CODE {
  ABLER = 'Abler',
  CLIP_STUDIO_PAINT = 'Clip Studio Paint',
  SKETCHUP = 'SketchUp',
  SNAPTOON = 'Snaptoon',
  PHOTOSHOP = 'Photoshop',
  PROCREATE = 'Procreate',
  ALPHA = 'Alpha',
  BETA = 'Beta',
}

export const mainTopSlideCode = {
  ko: 2610123110,
  en: 481688864,
  zh: 2627400439,
  ja: 401723616,
};

export const mainTopSlideCodePc = {
  ko: 1231756217,
  en: 2303568241,
  zh: 1205716091,
  ja: 1020654418,
};
export const mainTopSlideCodeTablet = {
  ko: 1122280607,
  en: 741777235,
  zh: 768434286,
  ja: 1296492267,
};
export const mainTopSlideCodeMobile = {
  ko: 4267842447,
  en: 1067194071,
  zh: 2545322572,
  ja: 4019626305,
};

// 메인 프로모션 타이머 표시 구좌 고유 번호들
export const mainProductTimerSno = 16;

// 알림 정보 정의 값
export enum NOTIFICATION_CODE {
  ACON_CASH = 'aconcash',
}

// 대분류 정보
export const MAIN_CATEGORY = {
  CARTOON: 'toonstyle',
  REALISTIC: 'realistic',
};

export const MAIN_CATEGORY_NAME = {
  [MAIN_CATEGORY.CARTOON]: 'mainCategory:cartoon',
  [MAIN_CATEGORY.REALISTIC]: 'mainCategory:realistic',
};

// 대분류 리스트
export const MAIN_CATEGORY_LIST = [MAIN_CATEGORY.CARTOON, MAIN_CATEGORY.REALISTIC];

// 애니메이션 정의 코드
export const TRANSITION_CODE = {
  /** 표시 중 */
  ENTERING: 'entering',
  /** 표시 완료 */
  ENTERED: 'entered',
  /** 닫는 중 */
  EXITING: 'exiting',
  /** 닫기 완료 */
  EXITED: 'exited',
};

// 카테고리 코드 정보
export const CATEGORY_CODE = {
  /** 주제별 */
  SUBJECT: '269',
  /** 장르별 */
  GENRE: '251',
  /** 실사렌더 */
  REALISTIC: '312',
  /** 에이블러 */
  ABLER: '282003',
  /** 구 에이블러 카테고리 */
  OLD_ABLER: '282002001',
  // 블랙 프라이데이
  BLACK_FRIDAY: '097117',
};

// 신상체험단 AB 테스트 1주차 상품 리스트
export const NEW_PRODUCT_EXPERIMENTAL_LIST = [1000010610, 1000010579, 1000010581];

// 카테고리 SNO 정보
export const CATEGORY_SNO = {
  // 신작 SNO 번호
  NEW: 30,
  // 이주의 에이블러 Pick SNO 번호
  ABLER: 36,
};

export const PAGE_TYPE = {
  TOON: 'toon',
  GAME: 'game',
};

export const LOGGER_CATEGORY = {
  PAYMENT: 'payment',
  JOIN: 'join',
  WAKE: 'wake',
  FIRST_HALF_EVENT_2022: 'firstHalf2022',
  INQUIRY: 'inquiry',
  SCRAP: 'scrap',
  DOWNLOAD: 'download',
  NINE_HUNDRED_EVENT_2022: '900deal2022',
  HALLOWEEN_2022: 'halloween2022',
  END_OF_YEAR_2022: 'endOfYear2022',
};

export enum SIZE {
  /** 600 ~ */
  DESKTOP = 'md',
  /** 0 ~ 600 */
  MOBILE = 'xs',
}

export const headerNamespaces = [
  'unsupported',
  'bandBanner',
  'mode',
  'game',
  'menu',
];

export const footerNamespaces = [
  'footer',
];

export const defaultNamespaces = [
  ...headerNamespaces,
  ...footerNamespaces,
  'common',
  'error',
  'modal',
  'message',
];
