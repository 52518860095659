import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useLocalStorage } from 'react-use';
import Searcher from 'src/assets/Searcher';
import * as mixpanel from '@lib/mixpanel';
import { PATH_PAGE } from 'src/routes/paths';
import { throttleWrapper } from '@util/eventHandling';
import { LANGUAGE_CODE } from '../../../../src/constants';
import { Placeholder, SearchBox, SearchInput } from './styled';
import { List } from './list';

type props = {
  className?: string;
};

export const Search: React.FC<props> = ({ className }) => {
  // 페이지 이동
  const router = useRouter();
  // 키워드
  const [keyword, setKeyword] = useState('');
  // 포커스 여부
  const [isFocus, setIsFocus] = useState(false);
  // 번역도구
  const { t, i18n } = useTranslation(['menu']);
  // 최근 항목들
  const [recentItems, setRecentItems] = useLocalStorage<[string?]>('acon-search-keyword', []);

  // input focus
  const handleInput = () => {
    // 포커스 true
    setIsFocus(true);
  };
  // input blur
  const handleInputBlur = () => {
    // 포커스 false
    setIsFocus(false);
  };
  // 검색 메소드
  const searchKeyword = throttleWrapper(async () => {
    if (recentItems.includes(keyword)) {
      recentItems.splice(recentItems.indexOf(keyword), 1);
    } else {
      // 최근 검색 항목이 4이상이면 오래된 항목 삭제
      if (recentItems.length >= 5) recentItems.pop();
    }
    // 최근 검색어 추가
    recentItems.unshift(keyword);
    setRecentItems(recentItems);

    mixpanel.event('Trigger Search', {
      keyword: keyword,
      method: 'typing',
    });

    setIsFocus(false);
    return await router.push({
      pathname: PATH_PAGE.search,
      query: {
        keyword: encodeURIComponent(keyword),
      },
    });
  });
  // 최근 항목 전체 삭제 버튼 클릭 이벤트 처리기 메소드
  const handleRemoveAllItemButtonClick = () => {
    // 최근항목 초기화
    setRecentItems([]);
  };

  return (
    <SearchBox lang={i18n.language} className={className}>
      {!isFocus && !keyword && <Placeholder paddingTop={i18n.language === LANGUAGE_CODE.KO ? 1 : 0}>{t('menu:gnb.search.placeholder')}</Placeholder>}
      <SearchInput
        value={keyword}
        onClick={handleInput}
        onFocus={handleInput}
        onBlur={handleInputBlur}
        onChange={(e) => {
          setKeyword(e.currentTarget.value);
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 13) searchKeyword();
        }}
      />
      <Searcher onClick={searchKeyword} className={'cursor-pointer'} />

      {isFocus && <List recentItems={recentItems} onBlurInput={handleInputBlur} onClickRemoveAllItemButton={handleRemoveAllItemButtonClick} />}
    </SearchBox>
  );
};
