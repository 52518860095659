import React, { CSSProperties } from 'react';
import { useTranslation } from 'next-i18next';
import { LANGUAGE_CODE } from 'src/constants';
import { CustomLink, CustomText } from '@components/ui';
import { RightArrowIcon } from 'src/assets/icons';
import { FlexRowAlignCenter } from '@components/wrapper';
import { TitleContent, TitleSkeleton, TitleSkeletonText, TitleSkeletonView, TitleSubText, TitleTextContainer, TitleTop, TitleView, TitleWrapper } from './styled';

interface TitleInfo {
  children?: React.ReactNode;
  isSkeleton?: boolean;
  isView?: boolean;
  viewLinkUrl?: string;
  style?: any;
  titleTextColor?: string;
  subTitle?: string;
  subTitleColor?: string;
  subTitleTextColor?: string;
  className?: string;
  innersetStyle?: any;
}

/**
 * 더보기, 조회조건 등 각 페이지에서 사용하는 타이틀
 */
export const Title: React.FC<TitleInfo> = ({
  children,
  viewLinkUrl = '',
  isView = false,
  isSkeleton = false,
  titleTextColor,
  subTitle,
  subTitleColor,
  subTitleTextColor,
  className = '',
  innersetStyle,
  ...style
}) => {
  const { i18n, t } = useTranslation(['common']);

  return (
    <TitleWrapper className={className} {...style}>
      <TitleTop style={innersetStyle}>
        {!isSkeleton ? (
          <TitleContent style={{ alignItems: 'center' }}>
            <TitleTextContainer>
              <CustomText letter={i18n.language === LANGUAGE_CODE.KO && -0.02} size={24} weight={700} color={titleTextColor || '#333'} lineHeight={'36px'}>
                {children}
              </CustomText>
              {subTitle && (
                <TitleSubText color={subTitleColor}>
                  {subTitle}
                </TitleSubText>
              )}
            </TitleTextContainer>
            {isView && (
              <TitleView>
                <CustomLink href={viewLinkUrl}>
                  <FlexRowAlignCenter>
                    <CustomText size={15} lineHeight={'120%'} weight={700}>
                      {t('common:seeMore')}
                    </CustomText>
                    <RightArrowIcon size={16} />
                  </FlexRowAlignCenter>
                </CustomLink>
              </TitleView>
            )}
          </TitleContent>
        ) : (
          <TitleSkeleton>
            <TitleSkeletonText />
            {isView && <TitleSkeletonView />}
          </TitleSkeleton>
        )}
      </TitleTop>
    </TitleWrapper>
  );
};
