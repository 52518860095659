import styled from 'styled-components';
import React from 'react';
import { IGnbContainerProps } from './Gnb.types';

export const GnbContainer = styled.div<IGnbContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => 'height: ' + props.height + 'px'};
  width: 100%;
  background-color: ${(props: any) => props.theme.colors.gnbBg};
  z-index: 1;
`;

export const GnbMobileSearchWrapper = styled.div<React.CSSProperties>`
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  padding: 10px 16px;
  background-color: #ffffff;
  justify-content: space-between;
  align-items: center;
`;
