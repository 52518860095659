import React, { Fragment, useCallback, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import gnbMenuList from 'public/gnbMenuList.json';
import GnbHome from 'src/assets/GnbHome';
import { HoverItem } from '@components/wrapper';
import Grid from '@components/layout/Grid/Grid';
import CategoryIcon from 'src/assets/icons/CategoryIcon';
import { SubGNBTab } from '../tab';
import { Category } from './category';
import * as Styled from './styled';

interface props {
  isMobile: boolean;
  isShowExplore: boolean;
  setIsShowExplore: React.Dispatch<any>;
}

export const SubGnb: React.FC<props> = ({ isMobile, isShowExplore, setIsShowExplore }) => {
  const router = useRouter();
  const { t } = useTranslation(['game', 'menu']);

  const handleExploreButtonClick = () => !isMobile && setIsShowExplore(true);
  const handleCategoryClose = () => setIsShowExplore(false);
  const handleCategoryRemoveClick = () => {
    location.hash = '#';
    handleCategoryClose();
  };

  const CategoryWrapper = useCallback(
    ({ children }) => {
      if (isMobile) return <Fragment />;
      return (
        <Fragment>
          <HoverItem isMobile={isMobile} className={'h-full'}>
            <Styled.CategoryButton onClick={handleExploreButtonClick} data-mixpanel-action="click" data-mixpanel-evt="GNB Click, GNB Click_category" data-mixpanel-name="category">
              {children}
            </Styled.CategoryButton>
          </HoverItem>
          <Category isShow={isShowExplore} isMobile={false} onClick={handleCategoryRemoveClick} onClose={handleCategoryClose} />
        </Fragment>
      );
    },
    [isShowExplore, isMobile],
  );

  useEffect(() => {
    // URL에 #category가 있을 경우 모델 표시 3D 모델찾기 모달 표시
    if (!isShowExplore && location.hash === '#category') {
      setIsShowExplore(true);
    }
  }, [typeof location !== 'undefined' && location.hash]);

  return (
    <Styled.Wrapper isMobile={isMobile}>
      <Grid>
        <Grid.Item>
          <Styled.Container isMobile={isMobile}>
            {gnbMenuList.menus.map((item) => {
              const isCategory = item.key === 'category';
              const isUpload = item.key === 'upload';

              if (isCategory) {
                return (
                  <CategoryWrapper key={item.key}>
                    <SubGNBTab
                      href={item.href}
                      isMobile={isMobile}
                      iconComponent={<CategoryIcon size={24} />}
                      iconMargin={8}
                      className={item.tagging}
                      displayName={t('menu:subgnb.findModel')}
                    />
                  </CategoryWrapper>
                );
              }
              if (isUpload) {
                return (
                  <HoverItem key={item.key} isMobile={isMobile} className={`h-full ${!isMobile ? 'ml-auto' : ''}`}>
                    <Styled.CommonLi isActive={router.pathname === item.isActivePath}>
                      <SubGNBTab
                        isMobile={isMobile}
                        iconComponent={!isMobile ? <GnbHome /> : null}
                        iconMargin={!isMobile ? 4 : 0}
                        className={item.tagging}
                        displayName={t(`game:${item.key}`)}
                        href={item.href}
                        style={{ fontSize: '14px', lineHeight: isMobile ? '100%' : '20px', fontWeight: isMobile ? 500 : 600 }}
                      />
                    </Styled.CommonLi>
                  </HoverItem>
                );
              }
              return (
                <HoverItem key={item.key} isMobile={isMobile} className={'h-full'}>
                  <Styled.CommonLi isActive={router.pathname === item.isActivePath}>
                    <SubGNBTab isMobile={isMobile} className={item.tagging} displayName={t(`menu:subgnb.${item.key}`)} href={item.href} />
                  </Styled.CommonLi>
                </HoverItem>
              );
            })}
          </Styled.Container>
        </Grid.Item>
      </Grid>
    </Styled.Wrapper>
  );
};
