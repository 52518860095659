import React from 'react';
import { CustomIcon } from '@components/ui/Icon';
import { PAGE_NAME } from 'src/constants';
import { useClip } from '@hooks';
import { throttleWrapper } from '@util/eventHandling';
import { ScrapWrapper } from './styled';

// 스크랩 정보
interface ScrapInfo {
  // 상품 번호
  goodsNo: string;
  // 스크랩 sno
  wishSno: string;
  // 디자인 바뀌기 전, 스크랩 아이콘 사용. 과도기에 잠시 사용하는 임시 변수
  legacy: boolean;
  // 프로모션 타입 ( 상수로 정의되어 있음, PAGE_COSMIC_DEAL : 우주 최저가, PAGE_NAME.COMET_DEAL : 혜성 특가 )
  type?: string;
  isDisabled?: boolean;
}

// [todo]: wishSno가 필요한지 확인해봐야 함.
/* eslint-disable @typescript-eslint/no-unused-vars */
const ScrapComponent: React.FC<ScrapInfo> = ({ goodsNo, wishSno, legacy, type = '', isDisabled = false, ...rest }) => {
  const { isClip, handleClip: onClip } = useClip(Number(goodsNo));

  // 스크랩 URL
  let scrapImageUrl: string;
  if (legacy) {
    scrapImageUrl = `/icons/goods/${isClip ? 'scrap-active-icon' : 'scrap-icon'}.png`;
  } else {
    // 혜성 특가일 경우 Active 아이콘을 변경
    const activeIconNameV2 = type === PAGE_NAME.COMET_DEAL ? 'comet-deal-scrap-active-icon-v2' : 'scrap-active-icon-v2';
    scrapImageUrl = `/icons/goods/${isClip ? activeIconNameV2 : 'scrap-icon-v2'}.png`;
  }

  // 스크랩 클릭 시 상태 변경
  const handleScrapWrapperClick = throttleWrapper(async (e) => {
    if (isDisabled) return;
    e.preventDefault();
    await onClip();
  });

  return (
    <ScrapWrapper isDisabled={isDisabled} {...rest} hide={isClip === null} onClick={handleScrapWrapperClick} data-mixpanel-action="click" data-mixpanel-evt="Scrap">
      <CustomIcon src={scrapImageUrl} alt="scrap" title="scrap" width="28px" height="28px" />
    </ScrapWrapper>
  );
};

export const Scrap = React.memo(ScrapComponent);
