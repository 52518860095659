import styled from 'styled-components';
import { Size } from '@components/type';

export const Wrapper = styled.div<{ size: Size; isShadow: boolean }>`
  position: relative;
  box-sizing: border-box;
  color: #fff;
  background-color: #dd43eb;
  border-radius: 4px;
  border: 0.6px solid #d200bd;
  font-weight: 500;
  text-align: center;

  ${(props) =>
    props.size === 'small' &&
    `
    line-height: 10px;
    padding: 4px 6px;
    height: 18px;
  `}

  ${(props) =>
    props.size === 'medium' &&
    `
    min-width: 61px;
    line-height: 22px;
    padding: 6px;
    height: 24px;
    `}
    
  ${(props) =>
    props.isShadow &&
    `
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.24);
  `}
`;
