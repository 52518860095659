import styled from 'styled-components';
import { CustomText } from '@components/ui';
import { AddCartButton } from '../Card/cart';
import { EndSales } from '../Card/styled';

export const TitleText = styled(CustomText).attrs({
  lineHeight: '140%',
  autoLetter: true,
  size: 12,
  weight: 500,
})`
  width: 100%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: keep-all;
`;

export const Contents = styled.div<{ isDisabled: boolean }>`
  min-height: 54px;
  margin-top: 16px;

  ${(props) => props.isDisabled && 'pointer-events: none;'}
`;

export const ScrapCart = styled(AddCartButton)`
  margin: 0px;
  width: 28px;
  height: 28px;
  padding: 0px;
`;

export const Image = styled.div`
  border: 1px #eeeeee solid;
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  height: 112px;
  position: relative;
`;

export const MainImage = styled.div`
  cursor: pointer;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: 0.4s;
`;

export const SubImage = styled.div`
  cursor: pointer;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: 0.4s;
`;

export const Container = styled.div`
  width: 180px;
  overflow: hidden;
  &:not(:nth-child(4n)) {
    margin-right: 20px;
  }

  &:hover {
    ${MainImage} {
      opacity: 0;
    }
  }
`;

export const EndSalesSmall = styled(EndSales)`
  height: 28px;
`;
