/* eslint-disable @typescript-eslint/indent */
import styled from 'styled-components';
import { CustomText } from '@components/ui';
import { colors } from '@public/theme';
import { TRANSITION_CODE } from 'src/constants';
import { DarkGrayBorderButton } from '@components/ui/Button/styled';

export const Bg = styled.div<{ state: string }>`
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  z-index: 999;
  transition: 0.3s;

  ${(props) =>
    [TRANSITION_CODE.ENTERING, TRANSITION_CODE.ENTERED].includes(props.state) &&
    `
    display: block; 
    opacity: 1;
  `}

  ${(props) =>
    props.state === TRANSITION_CODE.EXITING &&
    `
    display: block; 
    opacity: 0;
  `}

  ${(props) =>
    props.state === TRANSITION_CODE.EXITED &&
    `
    display: none;
    opacity: 0;
  `}
`;

export const Modal = styled.div<{ state: string; isMobile: boolean; isShowSub: boolean }>`
  display: flex;
  flex-direction: column;
  position: fixed;
  box-sizing: border-box;
  text-align: left;
  line-height: 20px;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 1000;
  font-size: 12px;
  transition: width 0.3s;
  overflow: hidden;

  &::after {
    top: -5px;
    left: 35px;
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent white transparent;
  }

  ${(props) =>
    [TRANSITION_CODE.ENTERING, TRANSITION_CODE.ENTERED].includes(props.state)
      ? `
    width: ${props.isShowSub ? '529px' : '350px'};
  `
      : `
    width: 0px;
  `}

  ${(props) =>
    props.isMobile &&
    `
    max-width: 100%;
    width: 100%;
    margin: 0;
    top: 0;
    left: 0;
    position: fixed;
    overflow: auto;
  `}
`;

export const CategoryWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const MainCategoryGroup = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 24px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #9e9e9e;
    border-radius: 6px;
  }
`;

export const Placeholder = styled.div`
  pointer-events: none;
  position: absolute;
  font-size: 12px;
  color: #9e9e9e;
  line-height: 100%;
  top: 10px;
`;

export const CategoryContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const MainContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  width: 289px;
  padding: 28px 0px 28px 24px;
  border-right: 1px ${colors.gray.c19} solid;
  background-color: white;

  ${(props) =>
    props.isMobile
      ? `
    width: 100%;
    border-right: none;
    padding: 84px 24px 30px 24px;
    height: 100%;

    ${CategoryWrapper} {
      padding: 0px;
    }

    ${MainCategoryGroup} {
      width: 50%;
      padding: 0px;
      position: relative;
    }

    ${SubContainer} {
      margin-top: 26px;
      width: 50%;
      padding: 0px 11px;
    }

    ${MainCategoryButton} {
      width: 50%;
      height: 44px;
    }

    ${RealisticButton} {
      height: 44px;
      margin-top: 24px;
      position: absolute;
      bottom: 0px;
    }
  `
      : `
    height: 100vh;

    ${CategoryWrapper} {
      height: calc(100% - 204px);
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  `}
`;

export const SubCategoryGroupItem = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #333;
  padding: 9px 0px;
  display: flex;
  align-items: center;
  width: 192px;

  &:hover {
    opacity: 0.5;
  }
`;

export const MainCategoryGroupItem = styled.div<{ isActive?: boolean; isActiveUnderline?: boolean }>`
  color: #333;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: center;
  width: 240px;
  color: #000;
  cursor: pointer;
  padding: 8px 0px;
  padding-left: 8px;
  min-height: 32px;

  ${(props) =>
    props.isActive &&
    `
    background-color: ${colors.gray.c18};
  `}
  & > a {
    display: flex !important;
    width: 100%;
  }

  & > a > div > p {
    ${(props) =>
      props.isActiveUnderline &&
      `
      text-decoration: underline;
    `}
  }

  & > a.custom_badge > p:after {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    background-color: #ff00cd;
    margin-top: 3px;
    border-radius: 50%;
  }
`;

export const SubContainer = styled.div<{ state: string }>`
  width: 0px;
  overflow: hidden;
  background-color: white;
  transition: width 0.3s;

  ${(props) =>
    TRANSITION_CODE.ENTERED === props.state &&
    `
    transition: width 0s;
  `}

  ${(props) =>
    [TRANSITION_CODE.ENTERING, TRANSITION_CODE.ENTERED].includes(props.state) &&
    `
    width: 240px;
    padding: 0px 24px;
    padding-top: 54px;
  `};
`;

export const MobileClose = styled.div`
  height: auto;
  position: absolute;
  top: 30px;
  right: 22px;
  cursor:pointer;
`;

export const MobileCloseButton = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  top: 24px;
  left: 24px;

  &::before {
    position: absolute;
    width: 2px;
    height: 18px;
    left: 7px;
    background-color: #333;
    content: '';
    transform: rotate(45deg);
  }

  &::after {
    position: absolute;
    width: 2px;
    height: 18px;
    left: 7px;
    background-color: #333;
    content: '';
    transform: rotate(-45deg);
  }
`;

export const CategoryTitle = styled.p<{ isTwoLine: boolean }>`
  line-height: ${(props) => (props.isTwoLine ? '120%' : '100%')};
  word-wrap: break-word;
  display: block;
`;

export const MainCategoryTitle = styled(CategoryTitle)`
  width: calc(100% - 24px);
`;

export const SubCategoryTitle = styled(CategoryTitle)`
  cursor: pointer;
`;

export const MainCategoryButton = styled.div<{ isActive: boolean }>`
  width: 120px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.gray.c19};
  cursor: pointer;

  &:first-child {
    border-radius: 4px 0px 0px 4px;
  }

  &:last-child {
    border-radius: 0px 4px 4px 0px;
    border-left: none;
  }

  ${(props) =>
    props.isActive &&
    `
    background-color: ${colors.gray.c18};
  `}
`;

export const MainCategoryText = styled(CustomText)`
  position: relative;
`;

export const ArrowIcon = styled.div<{ rotate?: number }>`
  ${(props) => props.rotate && `transform: rotate(${props.rotate}deg);`}
`;

export const CategoryItems = styled.div``;

export const RealisticButton = styled(DarkGrayBorderButton)`
  width: 100%;
  margin-top: 28px;
`;

export const BannerWrapper = styled.div<{ isMobile?: boolean }>`
  padding-bottom: 24px;
  background-color: white;

  ${(props) =>
    !props.isMobile
      ? `
    position: absolute;
    padding: 24px;
    bottom: 0;
    left: 0;
    width: 288px;
    text-align: center;
  `
      : `
    padding-left: 24px;
    padding-right: 24px;
    width: 100%;
  `}
`;
export const MoveGameTapButton = styled.div`
  border: 1px solid rgba(188, 188, 190, 0.5);
  border-radius: 8px;
  width: 100%;
  padding: 12px 22.5px;
  margin-bottom: 32px;
`;

export const BannerLink = styled.a<{ isMobile?: boolean }>`
  width: 100%;
  text-align: center;
  margin-top: auto;
  ${(props) =>
    props.isMobile &&
    `
    div {
      position: relative;
      margin: 0 auto;
      padding-top: min(75%, 360px);
      max-width: 480px;
      width: 100%;
    }
  `}
  img {
    margin: 0 auto;
    border-radius: 10px;
  }
`;
