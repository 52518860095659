import React, { ReactNode } from 'react';
import { Size } from '@components/type';
import { CustomText } from '@components/ui';
import { Wrapper } from './styled';

type Props = {
  children: ReactNode;
  size?: Size;
  isShadow?: boolean;
  className?: string;
};

export default function DefaultBadge({ size = 'medium', className, isShadow = true, children }: Props) {
  return (
    <Wrapper size={size} isShadow={isShadow} className={className}>
      <CustomText color={'white'} size={size === 'medium' ? 11.5 : 10}>
        {children}
      </CustomText>
    </Wrapper>
  );
}
