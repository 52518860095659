import styled from 'styled-components';
import { IGridContainerProps, IGridItemContainerProps } from './Grid.types';
import { DEFAULT_COLUMNS, DEFAULT_GAP, DEFAULT_MARGIN } from './Grid.constants';

export const GridContainer = styled('div')((props: IGridContainerProps) => {
  const { theme, gap, sm, md, lg } = props;
  return {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'repeat(1,1fr);',
    [theme.breakpoints.up('lg')]: {
      // desktop
      gap: `${gap ? gap : DEFAULT_GAP.lg}px`,
      gridTemplateColumns: `repeat(${lg ? lg : DEFAULT_COLUMNS.lg}, 1fr)`,
      padding: `0 ${DEFAULT_MARGIN.lg}px`,
    },
    [theme.breakpoints.down('lg')]: {
      // desktop
      gap: `${gap ? gap : DEFAULT_GAP.lg}px`,
      gridTemplateColumns: `repeat(${lg ? lg : DEFAULT_COLUMNS.lg}, 1fr)`,
      padding: `0 ${DEFAULT_MARGIN.lg}px`,
    },
    [theme.breakpoints.down('md')]: {
      // tablet
      gap: `${gap ? gap : DEFAULT_GAP.md}px`,
      gridTemplateColumns: `repeat(${md ? md : DEFAULT_COLUMNS.md}, 1fr)`,
      padding: `0 ${DEFAULT_MARGIN.md}px`,
    },
    [theme.breakpoints.down('sm')]: {
      // mobile
      gap: `${gap ? gap : DEFAULT_GAP.sm}px`,
      gridTemplateColumns: `repeat(${sm ? sm : DEFAULT_COLUMNS.sm}, 1fr)`,
      padding: `0 ${DEFAULT_MARGIN.sm}px`,
    },
  };
});

export const GridItemContainer = styled('div')((props: IGridItemContainerProps) => {
  const { theme, sm, md, lg, xl } = props;
  return {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      // desktop
      gridColumn: xl || lg ? `span ${xl || lg};` : `span ${DEFAULT_COLUMNS.lg};`,
      display: xl === 0 || lg === 0 ? 'none' : 'flex',
    },
    [theme.breakpoints.down('lg')]: {
      // desktop
      gridColumn: lg ? `span ${lg};` : `span ${DEFAULT_COLUMNS.lg};`,
      display: lg === 0 ? 'none' : 'flex',
    },
    [theme.breakpoints.down('md')]: {
      // tablet
      gridColumn: md ? `span ${md};` : `span ${DEFAULT_COLUMNS.md};`,
      display: md === 0 ? 'none' : 'flex',
    },
    [theme.breakpoints.down('sm')]: {
      // mobile
      gridColumn: sm ? `span ${sm};` : `span ${DEFAULT_COLUMNS.sm};`,
      display: sm === 0 ? 'none' : 'flex',
    },
  };
});


export const GridLikeWrapper = styled.div(({ theme }) => {
  const { breakpoints } = theme;
  return {
    maxWidth: '1444px',
    margin: '0 auto 12px;',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    [breakpoints.up('lg')]: {
      padding: `0 ${DEFAULT_MARGIN.lg}px`,
    },
    [breakpoints.down('lg')]: {
      padding: `0 ${DEFAULT_MARGIN.lg}px`,
    },
    [breakpoints.down('md')]: {
      padding: `0 ${DEFAULT_MARGIN.md}px`,
    },
    [breakpoints.down('sm')]: {
      padding: `0 ${DEFAULT_MARGIN.sm}px`,
    },
    '& > div' : {
      width: '100%',
    },
  };
});
