import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  left: 2px;
  width: 34px;
  height: 35px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 33px;
  color: white;
  background-repeat: no-repeat;
  ${(props) => `background-image: url(${props['data-url']});`}
`;
