import axios from 'axios';

// throttle refresh request. at most once a second.
let refreshLock = false;

export function serializeAuthToken(accessToken: string, refreshToken: string): string {
  return `${accessToken}//${refreshToken}`;
}

export function deserializeAuthToken(authToken: string): { accessToken: string; refreshToken: string } {
  const [accessToken, refreshToken] = (authToken || '//').split('//');
  return {
    accessToken: accessToken || '',
    refreshToken: refreshToken || '',
  };
}

export function requestRefresh(tokenStorageHook) {
  const { authToken, setAuthToken, removeAuthToken } = tokenStorageHook;

  if (refreshLock) {
    // case when lock is not released for some reason.
    setTimeout(() => {
      refreshLock = false;
    }, 1000);

    // wait for refresh, before retry (Apollo Client)
    return new Promise((resolve) => {
      setTimeout(() => resolve(''), 3000);
    });
  }
  refreshLock = true;
  setTimeout(() => {
    refreshLock = false;
  }, 1000);

  const { accessToken, refreshToken } = deserializeAuthToken(authToken);

  return axios
    .post(
      process.env.NEXT_PUBLIC_AUTH_V2_URL + '/acon/refresh',
      JSON.stringify({ access_token: accessToken, refresh_token: refreshToken }),
      {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json' },
      },
    )
    .then(function (response) {
      setAuthToken(serializeAuthToken(response.data.access_token, response.data.refresh_token));
    })
    .catch(function (error) {
      removeAuthToken();
      location.href = '/users/login'; // [TODO] : can use nextjs router outside component?
      throw error; // error를 던져줘서, apollo onError 에서 같은 쿼리를 두번 보내지 않도록 함.
    });
}
